
.App {
  margin-top: 0px;
  padding-top: 0px;
  position: absolute;
  display: block;
  background-color: #DEE0E7;
  width: 100%;
  height: fit-content;
}

.LinkChip {
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  background-color: #0077B5;
  width: 20%;
  height: fit-content;
  margin-top: 2rem;
  padding: 4px;
  justify-content: center;
  margin: auto;
  text-align: center;  
}

.Navbar {
  position: fixed;
  min-width: 96%;
  height: 70px;
  background-color: #F1F1F1;
  left: 2%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  top: 20px;
  vertical-align: middle;
  flex-direction: row;
  display: flex;
  align-items: start;
  z-index: 2;
  box-shadow: 1px 4px 4px 0px rgb(116, 116, 116);
}

.NavbarBrand {
  margin: auto;
  margin-top: 2px;
  margin-left: 30px;
  vertical-align: middle;
}

.NavbarItem {
  margin: auto;
  vertical-align: middle;
  font-size: 19px;
  cursor: pointer;
}

.PageSpecificNavbarItem {
  margin: auto;
  vertical-align: middle;
  font-size: 19px;
  cursor: pointer;
  color: #F1F1F1;
  background-color: #2E3744;
  border-radius: 2px;
  height: fit-content;
}


.Island {
  margin-top: 10rem;
  width: 91%;
  max-width: 91%;
  min-height: 360px;
  max-height: calc(fit-content + 20px);
  background-color: #2E3744;
  color: #F1F1F1;
  left: 2%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  padding-bottom: 10px;
  vertical-align: middle;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  box-shadow: 1px 4px 4px 0px rgb(116, 116, 116);
}

.IslandHeader {
  border-radius: 20px 20px 0px 0px;
  color: #2E3744;
  background-color: #F1F1F1;
  min-width: 100%;
  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-size: large;
  display: block;
}


.IslandItem {
  border-radius: 2px;
}

.ChipGroup {
  display: flex;
  width: 40%;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 6px;
}

.Chip {
  width: fit-content;
  align-self: center;
  border-radius: 20px;
  padding: 8px;
  font-weight: 600;
  margin: auto;
  margin-top: 10px;
  color: #F1F1F1;
}

.CardDeck {
  width: 60%;
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.Card {
  border-radius: 20px;
  margin: auto;
  max-width: fit-content;
  box-shadow: 1px 4px 4px 0px rgb(116, 116, 116);
}

.Dock {
  display: flex;
  background-color: #F1F1F1;
  margin: auto;
  justify-content: center;
  text-align: center;
  width: 20%;
  border-radius: 40px;
  box-shadow: 1px 4px 4px 0px rgb(116, 116, 116);
}

.Footer {
  width: 100%;
  height: 60px;
  margin-top: 7rem;
  bottom: 0px;
  text-align: center;
  color: #F1F1F1;
  background-color: #2E3744;
  display: flex;
  justify-content: center;
}

.Gallery {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
  vertical-align: middle;
  align-items: center;
  padding: 40px;
}

.Shadow {
  box-shadow: 1px 4px 4px 0px rgb(116, 116, 116);
}

a {
  text-decoration: none;
  color: black;
  margin: inherit;
  padding: inherit;
}

p {
  font-family: "IBM Plex Mono";
}

.TextHeavy {
  font-family: "IBM Plex Mono";
  font-weight: 600;
}

.TextLight {
  font-family: "IBM Plex Mono";
  font-weight: 400;
}
